import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

const Header = ({ siteTitle }) => (
  <header className="bg-gray-200 pt-10 pl-5 pb-10 space-left2">
    <div>
      <h1 className="uppercase font-black mb-3">
        <Link to="/">{siteTitle}</Link>
      </h1>
    </div>
    <nav className="flex items-center justify-between flex-wrap">
      <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
        <div className="text-sm lg:flex-grow">
          <Link className="block mt-4 md:inline-block lg:mt-0 mr-4" to="/">
            Home
          </Link>
          <Link className="block mt-4 md:inline-block lg:mt-0 mr-4" to="/about">
            About
          </Link>
        </div>
      </div>
    </nav>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ``
}

export default Header
